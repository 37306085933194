import React from "react";
import { Stack, Checkbox, Divider, IconButton, Box } from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PaginationText from "../pagination/PaginationText";
import EnhancedCardActions from "./EnhancedCardAction";

const EnhancedCardList = ({
  data = [],
  renderItem,
  renderItemProps,
  noCheckbox = false,
  listActions = [],
  initialRowsSelected = [],
  totalElements,
  page,
  pageSize,
  numberOfElements,
  init,
  handlePageChange,
}) => {
  const [selectedRows, setSelectedRows] = React.useState(initialRowsSelected);
  const onSelectAllClick = () => {
    if (selectedRows.length < data.length) {
      setSelectedRows(data.slice());
    } else {
      setSelectedRows([]);
    }
  };

  const handleRenderItem = React.useCallback(
    (rowData, rowIndex) =>
      renderItem({
        rowData,
        rowIndex,
        selectedRows,
        setSelectedRows,
        noCheckbox,
        ...renderItemProps,
      }),
    [noCheckbox, renderItem, renderItemProps, selectedRows]
  );

  return (
    <>
      <Stack direction="row" spacing={3}>
        {!noCheckbox && (
          <Checkbox
            color="primary"
            indeterminate={
              selectedRows.length > 0 && selectedRows.length < data.length
            }
            checked={
              selectedRows.length > 0 && selectedRows.length === data.length
            }
            onChange={onSelectAllClick}
          />
        )}
        <EnhancedCardActions rows={selectedRows} listActions={listActions} />
        <Box sx={{ flex: 1 }}></Box>
        <Stack direction="row" alignItems="center">
          <PaginationText sx={{ mr: 2 }} totalElements={totalElements} page={page} pageSize={pageSize} numberOfElements={numberOfElements} init={init} />
          <IconButton disabled={page < 1} onClick={() => handlePageChange(page - 1)}><KeyboardArrowLeftIcon /></IconButton>
          <IconButton disabled={(page + 1) * pageSize > totalElements} onClick={() => handlePageChange(page + 1)}><KeyboardArrowRightIcon /></IconButton>
        </Stack>
      </Stack>
      <Divider variant="middle" />
      <Stack direction="column" spacing={6} my={4}>
        {data.map(handleRenderItem)}
      </Stack>
    </>
  );
};

export default EnhancedCardList;
