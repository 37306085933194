import React from "react";
import { Link } from "react-router-dom";
import { AppBar, Box, Stack, IconButton, Toolbar, Typography, Menu, MenuItem } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import SensetekLogo from '../assets/Logo/sensetek_logo_horizontal.png';
import SensetekLogoBlack from '../assets/Logo/sensetek_logo_horizontal_black.png';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HistoryIcon from '@mui/icons-material/History';
import PersonIcon from '@mui/icons-material/Person';
import { AccountActions } from "app/redux/account";
import { useDispatch } from "react-redux";

const IconLinkButton = ({ sx, ...props }) => (<IconButton component={Link} sx={{ color: 'white', ...sx }} {...props} />)

function DesktopHeader() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = React.useCallback(() => {
    dispatch(AccountActions.logout());
  }, [dispatch]);

  return (
    <AppBar position="sticky" sx={{ boxShadow: "none", px: 2, py: 1, bgcolor: '#1C2532' }}>
      <Toolbar
        sx={{
          display: "flex",
          direction: "row",
          justifyContent: "space-between",
        }}>
        <Stack spacing={3} direction="row" alignItems="center" sx={{ py: 1 }}>
          <Link to={'/'}>
            <Box component={'img'} src={SensetekLogo} sx={{ maxHeight: '44px' }} alt="sensetekLogo" />
          </Link>
          {/* Here should be Drop down?? */}
          <Typography>Sensestek Group</Typography>
        </Stack>
        {/* todo: wait to confirm linked location */}
        <Stack spacing={3} direction="row" sx={{ py: 1 }}>
          <IconButton onClick={handleMenu} color="inherit"><PersonIcon /></IconButton>
          <Menu
            id="user-profile-menu"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
          <IconLinkButton to="/activity"><HistoryIcon /></IconLinkButton>
          {/*<IconLinkButton to="/my-account"><NotificationsIcon /></IconLinkButton>
           todo: add red dot when there are Notifications */}
          {/* <Badge color="error" badgeContent="" variant="dot"> <NotificationsIcon /> </Badge> */}
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

// todo: refactor as MobileBottom will have two version ==
function MobileHeader() {
  return (
    <AppBar position="fixed"
      sx={{

        display: "flex",
        direction: "row",
        justifyContent: "space-between",
        bgcolor: '#fff'
      }}>
      <Stack spacing={3} direction="row" sx={{ alignItems: 'center', py: 1, pl: 3 }}>
        <Box component={'img'} src={SensetekLogoBlack} sx={{ maxHeight: '32px' }} alt="sensetekLogo" />
        {/* Here should be Drop down?? */}
        <Typography sx={{ color: "#000" }}>Company Name</Typography>
      </Stack>
    </AppBar>
  );
}

function MobileBottom() {
  return (
    // todo: button click function
    <AppBar position="fixed" color="primary" sx={{
      bgcolor: '#0AB2AA',
      top: 'auto',
      bottom: 0,
      px: '76px',
    }}>
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <IconButton color="inherit" aria-label="open drawer">
          <PersonIcon />
        </IconButton>
        <IconButton color="inherit">
          <HistoryIcon />
        </IconButton>
        <IconButton color="inherit">
          <NotificationsIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
// Rember to do sites bottom


const Header = () => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
  return isMobile ? <><MobileHeader /><MobileBottom /></> : <DesktopHeader />
}

export default Header;
