import axios from 'axios';
import { AccountActions } from 'app/redux/account';
import { API_BASE_URL } from 'app/Config';

const defaultRequestConfig = {};

class AxiosForThunk {
  thunkAPI = null;
  constructor(thunkAPI) {
    this.thunkAPI = thunkAPI;
  }

  handleException(e) {
    // console.log(e.response?.status);
    // console.log(e.response?.data);
    if (e?.response?.status === 403 || e?.response?.status === 401) {
      this.thunkAPI.dispatch(AccountActions.logout());
    }
  };

  addApiUrl(relativePath) {
    return `${API_BASE_URL}${relativePath}`;
  }

  getAuthorization() {
    const account = this.thunkAPI.getState()?.account || {};
    // console.log(account);
    if (account?.user?.token) {
      return { Authorization: `Bearer ${account.user.token}` };
    }
    return null;
  };

  buildConfig(config = {},) {
    const { headers, ...rest } = config;
    return {
      ...defaultRequestConfig,
      ...rest,
      headers: {
        ...this.getAuthorization(),
        ...headers,
      },
    };
  }

  async get(url, config = {}) {
    try {
      const response = await axios.get(url, this.buildConfig(config));
      return response;
    } catch (e) {
      this.handleException(e);
      throw e;
    }
  }

  async post(url, data, config = {}) {
    try {
      const response = await axios.post(url, data, this.buildConfig(config));
      return response;
    } catch (e) {
      this.handleException(e);
      throw e;
    }
  }
  async patch(url, data, config = {}) {
    try {
      const response = await axios.patch(url, data, this.buildConfig(config));
      return response;
    } catch (e) {
      this.handleException(e);
      throw e;
    }
  }
  async put(url, data, config = {}) {
    try {
      return await axios.put(url, data, this.buildConfig(config));
    } catch (e) {
      this.handleException(e);
      throw e;
    }
  }
  async delete(url, config = {}) {
    try {
      const response = await axios.delete(url, this.buildConfig(config));
      return response;
    } catch (e) {
      this.handleException(e);
      throw e;
    }
  }
}

export default AxiosForThunk;
export const createAxios = (thunkAPI) => new AxiosForThunk(thunkAPI);