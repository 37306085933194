import React from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
// import { styled } from "@mui/material/styles";
import Helmet from "react-helmet";
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckboxButton from "app/components/form/CheckboxButton";
import FilledTextField from 'app/components/form/FilledTextField';

// global paper list 
// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   ...theme.typography.body2,
//   width: `100%`,
//   padding: theme.spacing(4),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
// }));


const GROUP_OPTIONS = [
  {
    name: "Personal Sensor Group 1",
    code: "senor_1",
  },
  {
    name: "Personal Sensor Group 2",
    code: "senor_2",
  },
  {
    name: "Personal Sensor Group 3",
    code: "senor_3",
  },
];

const ROLE_OPTIONS = [
  {
    name: "Viewer",
    code: "viewer",
  },
  {
    name: "Admin",
    code: "Admin",
  },
];

const ActivityFilter = () => {
  return (
    <>
      {/* todo: bottons height are diferent with other page */}
      <Stack direction="row" sx={{ mb: '36px' }}>
        <CheckboxButton
          data={GROUP_OPTIONS}
          buttonLabel="Select Sensor Groups"
          checkMasterLabel="All Sensor Groups"
        />
        <CheckboxButton
          data={ROLE_OPTIONS}
          buttonLabel="Select Role"
          checkMasterLabel="All Role"
        />
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <FilledTextField
          placeholder="Search specific member"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ width: 300 }}
        />
        <Button
          variant="contained"
          color="blueButton"
          startIcon={<AddCircleIcon />}
        >
          Add New Members
        </Button>
      </Stack>
    </>

  );
};

// const MEMBER_DATA = [
//   {
//     name: 'Annie Chan',
//     email: 'abc@sensetak.com',
//     group: 'All sensor group',
//     role: 'Admin',
//   },
// ]

// todo: call back MEMBER_DATA 
const Memberlist = () => {
  //   const userInfo = React.useMemo((user) => {
  //     return (
  //       <Item>
  //         <Stack direction="row" justifyContent="space-between" alignItems="center">
  //           <Stack direction="row">
  //             <Box>
  //               <Avatar>A</Avatar>
  //             </Box>
  //             <Box sx={{ textAlign: 'left', ml: '38px' }}>
  //               <Typography>{user.name}</Typography>
  //               <Typography><span>{user.email}</span></Typography>
  //             </Box>
  //           </Stack>
  //           <Box>
  //             <Typography>{user.group}</Typography>
  //           </Box>
  //           <Box>
  //             <Typography>{user.role}</Typography>
  //           </Box>
  //           <Box>
  //             <IconButton aria-label="settings">
  //               <MoreVertIcon />
  //             </IconButton>
  //             {/* onclick */}
  //           </Box>
  //         </Stack>
  //       </Item>
  //     )
  //   },
  //     [],
  //   );
};


const Members = () => {
  return (
    <Container maxWidth={false}>
      <Helmet title="Members Page" />
      <Container maxWidth="lg">
        <Typography>Member</Typography>
        <Box sx={{ marginBottom: 10 }}>
          <ActivityFilter />
        </Box>
        <Divider />
        <Memberlist />
      </Container>
    </Container>
  )
};
export default Members;