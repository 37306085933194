import React from 'react';
import Helmet from 'react-helmet';
import { useDispatch } from 'react-redux';
import useJoiForm from 'app/helpers/useJoiForm';
import { AccountActions } from 'app/redux/account';
import axios from 'app/helpers/axios';
import ControlledTextField from 'app/components/form/ControlledTextField';
import { Checkbox, FormControlLabel, Box, Button, Card, CardActions, CardContent } from '@mui/material';
import FieldRow from 'app/components/common/FieldRow';
import ContentContainer from 'app/components/common/ContentContainer';


const LoginForm = () => {
  const dispatch = useDispatch();
  const handleSubmit = React.useCallback(async (values) => {
    const { data } = await axios.post(axios.addApiUrl('/login'), values);
    dispatch(AccountActions.login(data));
  }, [dispatch]);

  const { control, onFormSubmit, } = useJoiForm({
    onSubmit: handleSubmit,
    defaultValues: {
      username: '',
      password: '',
    },
  });
  // console.log(errors);
  return (
    //Todo: interface align
    <Card component="form" onSubmit={onFormSubmit} sx={{ py: '10px', boxShadow: 'none', backgroundColor: '#F4F6F8' }}>
      {/* <CardHeader title="Login" titleTypographyProps={{ component: 'h1' }} /> */}
      <CardContent>
        <FieldRow>
          <ControlledTextField
            control={control}
            name="username"
            label="Username"
            variant="outlined"
            rules={{ required: 'Required.' }}
            fullWidth
          />
        </FieldRow>
        <FieldRow>
          <ControlledTextField
            control={control}
            name="password"
            label="Password"
            variant="outlined"
            type="password"
            rules={{ required: 'Required.' }}
            fullWidth
          />
        </FieldRow>

      </CardContent>
      <CardActions>
        <Button type="submit" variant='contained' fullWidth>Login</Button>
      </CardActions>
      <Box alignItems='center' sx={{ my: 2 }}>
        <FormControlLabel control={<Checkbox />} label="Remember me on this computer" />
        {/*<Link to={"#"}>Forgot password?</Link>*/}
      </Box>
    </Card>
  )
}


export default function Login() {
  return (
    <>
      <Helmet title="Login" />
      <ContentContainer sensetekLogo title={"Sign in to Sensestek"}>
        <LoginForm />
        {/*<Typography sx={{ display: "flex" }}>New to Sensetek? <Link to={"/site/request-password-reset"}>Create an account</Link>.</Typography>*/}
      </ContentContainer>
    </>
  );
}