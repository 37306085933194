import { MenuItem, TextField } from "@mui/material";
import React from "react";

const SimpleSelect = ({
  data = [],
  defaultValue = '',
  ...props
}) => {
  const [value, setValue] = React.useState(defaultValue);
  const handleChange = React.useCallback((event) => {
    setValue(event.target.value);
  }, []);

  return (
    <TextField
      value={value}
      onChange={handleChange}
      sx={{
        backgroundColor: 'white',
        borderRadius: 2,
      }}
      {...props}
      select
    >
      {data.map((option) => <MenuItem key={option.code} value={option.code}>{option.name}</MenuItem>)}
    </TextField>
  );
};

export default SimpleSelect;
