import { FormLabel, TextField } from '@mui/material';
import React from 'react';

const FilledTextField = ({
  id,
  label = "",
  labelProps = {},
  sx = {},
  error,
  ...props
}) => {
  return (
    <>
      {label ? <FormLabel htmlFor={id} error={error} sx={{ display: 'block', my: 1, color: '#637381', fontWeight: '700', fontSize: '0.875rem' }} {...labelProps}>{label}</FormLabel> : null}
      <TextField
        error={error}
        id={id}
        variant="filled"
        sx={{
          '& .MuiFilledInput-root': {
            border: '1px solid #DFE3E8',
            backgroundColor: 'white',
            borderRadius: '8px',
            height: '100%',
            '&:before': {
              display: 'none',
            },
            '&:after': {
              display: 'none',
            },
          },
          '& .MuiFilledInput-input': {
            padding: '0.2rem 0.875rem',
          },
          ...sx
        }}
        {...props}
      />
    </>
  );
}

export default FilledTextField;