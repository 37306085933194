// import { useSockentIoClient } from 'app/hooks/SocketIOClientProvider';
import {
  Container,
  Button,
  Paper,
  Stack,
  Box,
  Typography,
  IconButton,
  Checkbox,
  InputAdornment,
  Menu,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import StarIcon from "@mui/icons-material/Star";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import SearchIcon from '@mui/icons-material/Search';
import EnhancedCardList from "app/components/card/EnhancedCardList";
import useToggle from "app/hooks/useToggle";
import useSites from "app/hooks/Sites/useSites";
import { asDateTime } from "app/helpers/formatter";
import AddMemberModal from "./AddMemberModal";
import CheckboxButton from "../../components/form/CheckboxButton";
import SimpleSelect from "./Buttons/SimpleSelect";
import FilledTextField from "app/components/form/FilledTextField";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  width: `100%`,
  padding: theme.spacing(4),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const StyledBall = styled(Box)((active) => ({
  backgroundColor: active ? "#00C064" : "#eee",
  width: "20px",
  height: "20px",
  borderRadius: "50%",
}));

const renderSiteCard = props => <SiteCard key={props.rowData._id} {...props} />
const SiteCard = ({ rowData, noCheckbox, setSelectedRows, selectedRows, toggleAddMember }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const isSelected = (_id) => selectedRows.findIndex((ele) => ele._id === _id) >= 0;
  const rowSelected = isSelected(rowData._id);
  const handleRowClick = () => {
    if (rowSelected) {
      setSelectedRows(selectedRows.filter((ele) => ele._id !== rowData._id));
    } else {
      setSelectedRows(selectedRows.concat([rowData]));
    }
  };

  return (
    <Box>
      <Stack direction="row" spacing={2} alignItems="center">
        {!noCheckbox && (
          <Box><Checkbox checked={rowSelected} onChange={handleRowClick} /></Box>
        )}
        <Item>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Box>{rowData?.active ? <StyledBall /> : <StyledBall />}</Box>
            <Box
              style={{
                textAlign: "left",
                minWidth: "50%",
                flexBasis: "50%",
                color: "#333"
              }}
              component={Link}
              to={`/sites/${rowData._id}/analytics/dashboard`}
              sx={{ textDecoration: 'none' }}
            >
              <Stack direction="column" spacing={1}>
                <Typography variant="p" style={{ fontWeight: "900" }}>
                  {rowData?.name}
                </Typography>
                <Typography variant="p">{rowData?.district}</Typography>
              </Stack>
            </Box>
            <Box>
              <Stack direction="row" alignItems="center" spacing={1}>
                <PersonOutlineIcon />
                {`${rowData?.members?.length || 0} members`}
              </Stack>
            </Box>
            <Box style={{ flex: 1, minWidth: "15%" }}>
              <Stack
                direction="column"
                justifyContent="flex-end"
                alignItems="self-end"
                spacing={1}
                style={{ textAlign: "end" }}
              >
                <Typography variant="p" style={{ color: "#637381" }}>
                  Last Updated
                </Typography>
                {asDateTime(rowData?.updatedAt)}
              </Stack>
            </Box>
            <Box style={{ flex: 1 }}>
              <IconButton aria-label="favourite" onClick={(e) => { e.preventDefault(); }}>
                <StarIcon />
              </IconButton>
            </Box>
            <Box style={{ flex: 1 }}>
              <IconButton onClick={handleMenu}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                id={`${rowData._id}-more-menu`}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={toggleAddMember}>
                  Add Memeber to Site
                </MenuItem>
              </Menu>
            </Box>
          </Stack>
        </Item>
      </Stack>
    </Box>
  );
};

const SORT_OPTION = [
  {
    name: "Most Recently Updated",
    code: "update",
  },
  {
    name: "Most Recently Added",
    code: "add",
  }
];

const DASHBOARD_OPTION = [
  {
    name: "Kowloon",
    code: "kowloon",
  },
  {
    name: "Hong Kong Island",
    code: "hong_kong_island",
  },
  {
    name: "N.T.",
    code: "nt",
  },
];

const Filters = () => {
  return (
    <Stack direction="row">
      <Button
        variant="contained"
        color="btnPrimary"
        startIcon={<StarBorderIcon />}
        style={{ marginRight: 10 }}
      >
        Favourite
      </Button>
      <CheckboxButton
        data={DASHBOARD_OPTION}
        checkMasterLabel="All Dashboard"
        buttonLabel="Select Dashboard"
        hasCheckMasterControl
      />
      <SimpleSelect data={SORT_OPTION} defaultValue='update' />
      <Box sx={{ flex: 1 }}></Box>
      <FilledTextField
        placeholder="Search"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{ width: 300 }}
      />
    </Stack>
  );
};

const Home = () => {
  const [addMemberOpen, { toggle: toggleAddMember, close: closeAddMember }] = useToggle(false);
  const { state, handlePageChange } = useSites(true);
  const {
    data,
    totalElements,
    page,
    pageSize,
    numberOfElements,
    init,
  } = state;

  // const onGenerateReport = React.useCallback(() => {
  //   console.log("generate report");
  // }, []);

  // const CardActions = [
  //   {
  //     name: "Add Selected to",
  //     fn: () => { },
  //   },
  //   {
  //     name: "Generate Report",
  //     fn: onGenerateReport,
  //   },
  //   {
  //     name: "Add Member to Selected",
  //     fn: toggleAddMember,
  //   },
  // ];

  // const ws = useSockentIoClient();
  // React.useEffect(() => {
  //   ws?.on('Home', function (data) {
  //     console.log(data);
  //   });
  //   return () => ws?.off('Home');
  // }, [ws]);

  return (
    <Container sx={{ mt: 10 }} maxWidth={false}>
      <Helmet title="Home" />
      <Container maxWidth="lg">
        <Box sx={{ marginBottom: 10 }}>
          <Filters />
        </Box>
        <EnhancedCardList
          data={data}
          totalElements={totalElements}
          numberOfElements={numberOfElements}
          page={page}
          pageSize={pageSize}
          renderItem={renderSiteCard}
          renderItemProps={{
            toggleAddMember
          }}
          init={init}
          handlePageChange={handlePageChange}
        />
        <AddMemberModal
          open={addMemberOpen}
          toggleOpen={toggleAddMember}
          handleSubmit={closeAddMember}
        />
      </Container>
    </Container>
  );
};
export default Home;
