import { flatMap } from "lodash";
import stringToColor from "string-to-color";
import {
  Stack,
  Typography,
  Paper,
  Box,
  Tooltip,
} from "@mui/material";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import React from "react";
import { useDispatch } from "react-redux";
import useDeviceSettings from "app/hooks/Sites/Dashboard/useDeviceSettings";
import useDevicesOfSelectedArea from "app/hooks/Sites/Dashboard/useDevicesOfSelectedArea";
import { DashboardSelectionActions } from "app/redux/SiteDashboard/Selection";
import useDashboardSelection from "app/hooks/Sites/Dashboard/useDashboardSelection";
import useGetLatestValueQuery from "app/hooks/Sites/Dashboard/useGetLatestValueQuery";
import { asNumber } from 'app/helpers/formatter';
import InfoCardCarousel from "./InfoCardCarousel";
import { renderDeviceValue } from "./helpers";

const useSelectDevice = ({ deviceID, valueType }) => {
  const dispatch = useDispatch();
  const { deviceValue = [] } = useDashboardSelection();
  const isSelected =
    deviceValue.findIndex(
      (r) => r.deviceID === deviceID && r?.valueType === valueType
    ) > -1;
  const selectDeviceValue = React.useCallback(() => {
    // console.log({ deviceID, valueType });
    dispatch(
      DashboardSelectionActions.selectDeviceValue({ deviceID, valueType })
    );
  }, [deviceID, dispatch, valueType]);
  const deselectDeviceValue = React.useCallback(() => {
    // console.log({ deviceID, valueType });
    dispatch(
      DashboardSelectionActions.deselectDeviceValue({ deviceID, valueType })
    );
  }, [deviceID, dispatch, valueType]);

  const toggleSelectedDeviceValue = React.useCallback(() => {
    // console.log('toggleSelectedDeviceValue', isSelected);
    if (isSelected) {
      deselectDeviceValue();
    } else {
      selectDeviceValue();
    }
  }, [isSelected, selectDeviceValue, deselectDeviceValue]);
  return {
    isSelected,
    selectDeviceValue,
    deselectDeviceValue,
    toggleSelectedDeviceValue,
  };
};

const green = '#54d62c';
const yellow = '#ffc107';
const red = '#ff4842';
const renderStatus = (value, { min, max, warning_upper, warning_lower }) => {
  const isGreen = value <= warning_upper && value >= warning_lower;
  const isYellow = !isGreen && value <= max && value >= min;
  return (
    <Box
      sx={{
        borderRadius: "50%",
        width: 16,
        height: 16,
        bgcolor: (isGreen ? green : (isYellow ? yellow : red))
      }}
    />
  );
};
const renderTrend = (current, average) => {
  if (!current) {
    return null;
  }
  // console.log('renderTrend', current, average);
  let IconComponent = TrendingDownIcon;
  let color = red;
  if (asNumber(current) === asNumber(average)) {
    IconComponent = TrendingFlatIcon;
    color = '#111';
  } else if (current > average) {
    IconComponent = TrendingUpIcon;
    color = green;
  }
  return <IconComponent htmlColor={color} />
}

const DeviceValueCard = ({ device, valueType }) => {
  const { data: { current, average } = {} } = useGetLatestValueQuery({ deviceID: device.deviceID, valueType: valueType.key });
  const { isSelected, toggleSelectedDeviceValue } = useSelectDevice({
    deviceID: device.deviceID,
    valueType: valueType.key,
  });

  const color = stringToColor(`${device.deviceID}-${valueType.key}`);
  return (
    <Paper
      sx={{
        p: 1.5,
        width: 220,
        height: 130,
        cursor: "pointer",
        borderRadius: 4,
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: "transparent",
        ...(isSelected && {
          // borderColor: 'info.main',
          borderColor: color,
        }),
      }}
      onClick={toggleSelectedDeviceValue}
    >
      <Stack direction="row" alignItems="start" gap={1}>
        <Box sx={{ flex: 1, overflow: "hidden" }}>
          <Tooltip title={device?.deviceName}>
            <Typography sx={{ fontSize: "0.975rem", fontWeight: "700" }}>
              {valueType.label}
            </Typography>
          </Tooltip>
          <Typography sx={{ fontSize: "0.775rem", color: "text.light" }} noWrap>
            {device?.deviceName}
          </Typography>
        </Box>
        <Box>
          {(valueType?.min || valueType?.max) && current?.value ? renderStatus(Number(current?.value), valueType) : null}
        </Box>
      </Stack>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography
          sx={{
            fontSize: 22,
            fontWeight: "700",
            py: 2,
            overflow: "hidden",
            flex: 1,
          }}
          noWrap
        >
          {renderDeviceValue(current || {})}
        </Typography>
        {renderTrend(current?.value, average)}
      </Stack>
    </Paper>
  );
};

const DeviceValuesList = () => {
  const placeholderIDs = useDevicesOfSelectedArea();
  const { data: deviceSettings } = useDeviceSettings();
  const filteredDevices = deviceSettings?.filter((device) => placeholderIDs.findIndex((placeholderID) => placeholderID === device?.placeholderID) > -1) || [];

  return (
    <InfoCardCarousel>
      {flatMap(filteredDevices, (device) => device.valueType?.map((valueType) => ({ device, valueType }))) // flatMap to device + single valueType
        .map(({ device, valueType }) => (
          <DeviceValueCard
            key={`${device._id}-${valueType.key}`}
            device={device}
            valueType={valueType}
          />
        ))}
    </InfoCardCarousel>
  );
};

export default DeviceValuesList;
