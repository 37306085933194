
import stringToColor from 'string-to-color';
import { uniqWith } from 'lodash';
import { Button, Paper, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';
import {
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Line,
  LineChart,
  Tooltip,
} from 'recharts';
import useGetLatestValueQuery from "app/hooks/Sites/Dashboard/useGetLatestValueQuery";
import useDashboardSelection from 'app/hooks/Sites/Dashboard/useDashboardSelection';
import { getUnit, renderDeviceValue } from './helpers';
import { DeviceValueChartDataProvider, useDeviceValueChartData } from 'app/hooks/Sites/Dashboard/DeviceValueChartData';

const DateRangeSelector = ({
  range,
  setRange,
}) => {
  return (
    <Stack direction="row" gap={1}>
      <Button variant='text' onClick={() => setRange('4H')} disabled={range === '4H'}>4H</Button>
      <Button variant='text' onClick={() => setRange('1D')} disabled={range === '1D'}>1D</Button>
      <Button variant='text' onClick={() => setRange('1W')} disabled={range === '1W'}>1W</Button>
      <Button variant='text' onClick={() => setRange('1M')} disabled={range === '1M'}>1M</Button>
      <Button variant='text' onClick={() => setRange('3M')} disabled={range === '3M'}>3M</Button>
      <Button variant='text' onClick={() => setRange('6M')} disabled={range === '6M'}>6M</Button>
    </Stack>
  );
}

const getYDomain = (valueType) => {
  // console.log(valueType);
  if (valueType === 'ph') {
    return [0, 14];
  } else if (valueType === 'temperature') {
    return [0, 35];
  } else if (valueType === 'do') {
    return [0, 12];
  } else if (valueType === 'salinity') {
    return [0, 4];
  }
  return undefined;
}

const getXDomain = (range) => {
  const now = DateTime.local();
  switch (range) {
    case '6M':
      return [DateTime.local().minus({ month: 6 }).startOf('day').toMillis(), now.startOf('day').toMillis()];
    case '3M':
      return [DateTime.local().minus({ month: 3 }).startOf('day').toMillis(), now.startOf('day').toMillis()];
    case '1M':
      return [DateTime.local().minus({ month: 1 }).startOf('day').toMillis(), now.startOf('day').toMillis()];
    case '1W':
      return [DateTime.local().minus({ weeks: 1 }).startOf('day').toMillis(), now.startOf('day').toMillis()];
    case '1D':
      return [DateTime.local().minus({ days: 1 }).startOf('hour').toMillis(), now.startOf('hour').toMillis()];
    case '4H':
    default:
      return [DateTime.local().minus({ hours: 4 }).startOf('minute').toMillis(), now.startOf('minute').toMillis()];
  }
}


const ShadowedTooltip = ({
  payload,
  label,
  active,
  tickFormatterX,
  range,
}) => {
  if (!active || !payload) {
    return null;
  }
  // console.log('payload', payload);
  return (
    <Paper sx={{ fontSize: 14, minWidth: 300, color: 'text.grey', py: 1.5, px: 2, boxShadow: '0px 3px 20px #0000001A' }}>
      <Typography fontSize="inherit" sx={{ mb: 1 }}>{tickFormatterX(label)}</Typography>
      {uniqWith(payload || [], (a, b) => (a.payload?.key === b.payload?.key)).map((row, index) =>
        <Stack key={`${row?.payload?.key}-${range}-${index}`} direction="row" justifyContent="space-between">
          <Typography fontSize="inherit" color={stringToColor(row?.payload?.key)}>
            {row?.payload?.valueType}
          </Typography>
          <Typography fontWeight="600" color={stringToColor(row?.payload?.key)}>
            {renderDeviceValue({ key: row?.payload?.valueType, value: row?.value })}
          </Typography>
        </Stack>
      )}
    </Paper>
  );
}



const MyGraph = ({ range, deviceValue, selected }) => {
  const data = useDeviceValueChartData();
  const tickFormatterX = React.useCallback((value) => {
    let luxonDate = DateTime.fromMillis(value);
    if (range === '4H' || range === '1D') {
      return luxonDate.toFormat('yyyy-MM-dd HH:mm');
    } else {
      return luxonDate.toFormat('yyyy-MM-dd');
    }
  }, [range]);
  // console.log({ graphData: data });
  return (
    <ResponsiveContainer width="100%" height={350}>
      <LineChart>
        <CartesianGrid strokeDasharray="3 3" />
        {deviceValue?.map(({ deviceID, valueType }, index) => {
          const key = `${deviceID}-${valueType}`;
          if (!data[key]?.length) {
            return null;
          }
          return (
            <XAxis
              hide={selected !== key}
              key={`${deviceID}-${valueType}-${range}-xaxis`}
              xAxisId={`${deviceID}-${valueType}-${range}-xaxis`}
              dataKey="date"
              domain={getXDomain(range)}
              allowDataOverflow={false}
              // interval='preserveStartEnd'
              scale="time"
              type="number"
              tickFormatter={tickFormatterX} />);
        })}
        {deviceValue?.map(({ deviceID, valueType }, index) => {
          const key = `${deviceID}-${valueType}`;
          if (!data[key]?.length) {
            return null;
          }
          return (
            <YAxis
              hide={selected !== key}
              key={`${deviceID}-${valueType}-${range}-yaxis`}
              yAxisId={`${deviceID}-${valueType}-${range}-yaxis`}
              type='number'
              dataKey='value'
              domain={getYDomain(valueType)}
              allowDataOverflow={true}
              unit={getUnit(valueType)}
              name={valueType}
            />);
        })}
        <Tooltip content={<ShadowedTooltip tickFormatterX={tickFormatterX} range={range} />} />
        {deviceValue?.map(({ deviceID, valueType }, index) => {
          const key = `${deviceID}-${valueType}`;
          if (!data[key]?.length) {
            return null;
          }
          return (
            <Line
              key={`${key}-${range}-line`}
              yAxisId={`${key}-${range}-yaxis`}
              xAxisId={`${key}-${range}-xaxis`}
              dataKey="value"
              type="linear"
              strokeWidth={2}
              stroke={stringToColor(key)}
              dot={key === selected}
              data={data[key]?.map(r => ({ ...r, date: DateTime.fromISO(r.dateStr).toMillis() })) || []}
            />);
        })}
      </LineChart>
    </ResponsiveContainer>
  )
}

const CurrentValue = ({
  deviceID,
  valueType,
  onSelectValue,
  selected,
}) => {
  const { data: { current } = {} } = useGetLatestValueQuery({ deviceID, valueType });
  const key = `${deviceID}-${valueType}`;
  const color = stringToColor(key);
  const handleClick = React.useCallback(() => {
    onSelectValue(key);
  }, [key, onSelectValue]);
  return (
    <Typography
      fontSize="1rem"
      fontWeight="700"
      color={color}
      onClick={handleClick}
      sx={{ cursor: 'pointer', textDecoration: selected === key ? 'underline' : 'none' }}>
      {valueType} : {renderDeviceValue(current)}
    </Typography>
  );
}

const DeviceValueChart = () => {
  const { deviceValue = [] } = useDashboardSelection();
  const [range, setRange] = React.useState('1D');
  const [selected, setSelected] = React.useState('');
  React.useEffect(() => {
    if (selected) {
      if (deviceValue?.findIndex(r => `${r.deviceID}-${r.valueType}` === selected) < 0) {
        // console.log('removed');
        // selected axis is no longer in the deviceValues, select another 1
        const newSelected = deviceValue?.[0] || null;
        if (newSelected) {
          setSelected(`${newSelected.deviceID}-${newSelected.valueType}`);
        } else {
          setSelected('');
        }
      }
      // selected axis is still in the deviceValues, do nothing
    } else if (deviceValue.length > 0) {
      // select the first one
      const newSelected = deviceValue?.[0] || null;
      if (newSelected) {
        setSelected(`${newSelected.deviceID}-${newSelected.valueType}`);
      } else {
        setSelected('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceValue.length]);

  return (
    <Paper sx={{ borderRadius: 2, position: 'relative', width: 720, p: 2, '& .recharts-responsive-container': { overflow: 'hidden' } }}>
      <DeviceValueChartDataProvider deviceValue={deviceValue} range={range}>
        <Stack gap={1} sx={{ px: 2 }}>
          <Typography color="text.light" fontSize="1.125rem" fontWeight="700" >Trending Summary</Typography>
          <Stack direction="row" gap={2} flexWrap="wrap">
            {deviceValue?.map(({ deviceID, valueType }) =>
              <CurrentValue deviceID={deviceID} valueType={valueType} key={`${deviceID}-${valueType}-cv`} onSelectValue={setSelected} selected={selected} />)}
          </Stack>
        </Stack >
        <Stack alignItems="flex-end" sx={{ mb: 2 }}>
          <DateRangeSelector range={range} setRange={setRange} />
        </Stack>
        <MyGraph deviceValue={deviceValue} range={range} selected={selected} />
      </DeviceValueChartDataProvider>
    </Paper >
  );
}

export default DeviceValueChart;
