import { flatMap } from 'lodash';
import { useParams } from 'react-router';
import useDashboardSelection from './useDashboardSelection';
import useSiteAreas from './useSiteAreas';

const useDevicesOfSelectedArea = () => {
  const { id } = useParams();
  const data = useSiteAreas(id)?.state?.data || [];
  const { siteAreaID } = useDashboardSelection();
  let filtered = siteAreaID ? data?.filter?.(r => r._id === siteAreaID) : data;
  return flatMap(filtered, 'placeholderIDs');
}

export default useDevicesOfSelectedArea;