import { Typography } from '@mui/material';
import React from 'react';


const PaginationText = ({
  totalElements = 0,
  page = 0,
  pageSize = 0,
  numberOfElements = 0,
  init = true,
  ...props
}) => {
  if (!init) {
    return <Typography>&nbsp;</Typography>;
  }
  if (totalElements <= 0) {
    return <Typography>No items.</Typography>;
  }
  const start = page * pageSize + 1;
  const end = page * pageSize + numberOfElements;
  return (
    <Typography {...props}>
      <Typography component="span" color="primary.main" sx={{fontWeight: "900"}}> {start} - {end} </Typography>
      /
      <Typography component="span" color="primary.main" sx={{fontWeight: "900"}}> {totalElements} </Typography>
    </Typography>
  );
}

export default PaginationText;