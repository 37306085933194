import React, { useState } from 'react';

export default function useToggle(initialValue = false) {
  const [isOpen, setOpen] = useState(initialValue);
  const toggle = React.useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);
  const open = React.useCallback(() => setOpen(true), []);
  const close = React.useCallback(() => setOpen(false), []);
  return [isOpen, { toggle, open, close }];
}