import { DateTime } from 'luxon';

const formatDatetime = (value, {
  toFormat = 'YYYY-MM-DD',
  nullValue = null,
}) => {
  if (!value) {
    return nullValue;
  }
  if (value instanceof Number) {
    return DateTime.fromSeconds(value).toFormat(toFormat);
  }
  return DateTime.fromISO(value).toFormat(toFormat);
}
const asDateTime = timestamp => formatDatetime(timestamp, { toFormat: 'yyyy-MM-dd HH:mm:ss' });
const asDate = timestamp => formatDatetime(timestamp, { toFormat: 'yyyy-MM-dd' });
const asNumber = (n, {
  decimal = 2,
  trimTailingZero = false,
} = {}) => {
  const number = Number(n);
  if (Number.isFinite(number)) {
    if (trimTailingZero) {
      return number.toFixed(decimal).replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1');
    }
    return number.toFixed(decimal);
  }
  return null;
};

export {
  formatDatetime,
  asDateTime,
  asDate,
  asNumber,
}