import { pickBy } from 'lodash';
import querystring from 'query-string';

const buildQuery = (search, sendEmpty = false) => {
  // we stored page zero-based.
  let data = { ...search.form, page: search?.page, pageSize: search?.pageSize };
  if (search.sort && search.sort.key) {
    data.sort = (search.sort.asc ? '' : '-') + search.sort.key;
  }
  // remove empty array
  data = pickBy(
    data,
    // filter empty array as querystring have strange behaviour
    value => (Array.isArray(value) ? Boolean(value.length) : true),
  );
  if (!sendEmpty) {
    // remove empty input to shorten the params
    data = pickBy(
      data,
      // filter empty array as querystring have strange behaviour
      value => Boolean(value),
    );
  }
  // return querystring.stringify(data, { arrayFormat: 'bracket' });
  return querystring.stringify(data, { arrayFormat: 'none' });
};

export default buildQuery;
