import React from "react";
// import { Link } from 'react-router-dom';
import { Button, Toolbar } from "@mui/material";

const EnhancedCardActions = ({ rows = [], listActions = [] }) => {
  return (
    <Toolbar variant="dense">
      {listActions.map(action => <Button key={action.name} disabled={rows.length < 1} onClick={action.fn}>{action.name}</Button>)}
    </Toolbar>
  );
};

export default EnhancedCardActions;
