import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { asDateTime } from 'app/helpers/formatter';
import useGetDeviceValuesQuery from 'app/hooks/Sites/Dashboard/useGetDeviceValuesQuery';
import useDevicesOfSelectedArea from 'app/hooks/Sites/Dashboard/useDevicesOfSelectedArea';
import React from 'react';
import { renderDeviceValue } from './helpers';
import useDeviceSettings from 'app/hooks/Sites/Dashboard/useDeviceSettings';

const DeviceName = ({ deviceID, placeholderID }) => {
  const { data } = useDeviceSettings();
  return data?.find(r => r.deviceID === deviceID || r.placeholderID === placeholderID)?.deviceName || deviceID;
}

const DeviceValuesTableBody = ({ placeholderIDs }) => {
  const { data } = useGetDeviceValuesQuery({ placeholderIDs });
  return (
    <TableBody>
      {data?.map((row) => (
        <TableRow key={`${row._id}`} hover>
          <TableCell sx={{ width: 20 }}><Box sx={{ borderRadius: '50%', bgcolor: 'success.main', width: 16, height: 16 }} /></TableCell>
          <TableCell><DeviceName deviceId={row.deviceID} placeholderID={row.placeholderID} /></TableCell>
          <TableCell>{row.key}</TableCell>
          <TableCell>{renderDeviceValue(row)}</TableCell>
          <TableCell>{asDateTime(row.createdAt)}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
}
const AllDeviceValuesTable = () => {
  const placeholderIDs = useDevicesOfSelectedArea();
  return (
    <Paper sx={{ borderRadius: 2, p: 2 }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell variant='head'>Status</TableCell>
              <TableCell variant='head'>Device</TableCell>
              <TableCell variant='head'>Type</TableCell>
              <TableCell variant='head'>Value</TableCell>
              <TableCell variant='head'>Timestamp</TableCell>
            </TableRow>
          </TableHead>
          {placeholderIDs?.length > 0 ? <DeviceValuesTableBody placeholderIDs={placeholderIDs} /> : null}
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default AllDeviceValuesTable;