import React from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";

const SimpleDatePicker = ({
  helperText,
  color = "primary",
  clearable = true,
  fullWidth = false,
  defaultValue,
  label = "",
  ...props
}) => {
  const [value, setValue] = React.useState('');

  const handleOnChange = React.useCallback((v) => {
    setValue(v);
  }, []);
  return (
    <DatePicker
      id={label}
      label={label}
      views={["year", "month", "day"]}
      {...props}
      onChange={(v) => {
        handleOnChange(v?.toISODate());
      }}
      inputFormat="yyyy-MM-dd"
      value={value ?? null}
      defaultValue={defaultValue ?? null}
      clearable={clearable}
      renderInput={(params) => (
        <TextField
          {...params}
          color={color}
          fullWidth={fullWidth}
          helperText={helperText}
          style={{ marginRight: 10 }}
          error={false}
        />
      )}
    />
  );
};

export default SimpleDatePicker;
