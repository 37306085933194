import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Link as RouterLink } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import AppRoutes from 'app/AppRoutes';
import { store, persistor } from 'app/redux/store';
import { BASENAME } from 'app/Config';
// import SocketIOClientProvider from 'app/hooks/SocketIOClientProvider';

const DEFAULT_FONTS = '"Public Sans", "Helvetica Neue", "Microsoft JhengHei", -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';
const THEME_COMMON = {
  palette: {
    text: {
      light: '#637381',
    },
    grey: {
      outline: '#DFE3E8',
    },
    primary: {
      main: "#2D2D2D"
    },
    secondary: {
      main: '#0AB2AA',
    },
    btnPrimary: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#cccccc',
      contrastText: '#000000',
    },
    blueButton: {
      light: '#7b93ff',
      main: '#3366ff',
      dark: '#003dcb',
      contrastText: '#ffffff',
    },
  },
  MuiButtonBase: {
    defaultProps: {
      LinkComponent: RouterLink,
    },
  },
  typography: {
    fontFamily: DEFAULT_FONTS,
  },
  components:
  {
    MuiButton: {
      styleOverrides: {
        root: {
          // border: '1px solid #DFE3E8',
          borderRadius: '8px',
          textTransform: 'none',
          boxShadow: 'none',
          '&:hover,&:focus':
          {
            backgroundColor: '#ffffff80',
            boxShadow: '0px 2px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 2px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)'
          },
          flex: 1,
          // '&:hover':
          // {
          //   backgroundColor: '#ffffff80',
          //   boxShadow: '0px 2px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 2px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)'
          // },

        },
      },
    },
  },

};

const DEFAULT_THEME = createTheme(THEME_COMMON);

function App() {
  return (
    <>
      <Helmet title="Sensestek IoT Dashboard" />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={DEFAULT_THEME}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <CssBaseline />
              <BrowserRouter basename={BASENAME}>
                {/* <SocketIOClientProvider> */}
                <AppRoutes />
                {/* </SocketIOClientProvider> */}
              </BrowserRouter>
            </LocalizationProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
