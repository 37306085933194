import useSWR from 'swr';
import axios from 'app/helpers/axios';

const transformResponse = (data) => {
  return data?.items || [];
}

const useGetGraphDataQuery = ({ deviceID, valueType, range }) => {
  const url = deviceID && valueType && range ? `/devicevalue/${deviceID}/${valueType}/graph/?range=${range}` : null;
  return useSWR(url, () => axios.get(axios.addApiUrl(url)).then(r => r.data).then(transformResponse), {
    focusThrottleInterval: 30000,
    refreshInterval: 60000
  });
}

export default useGetGraphDataQuery;