import React from "react";
import { Box, Container, Stack } from "@mui/material";
import DeviceCurrentValuesList from "./DeviceCurrentValuesList";
import DeviceValueChart from './DeviceValueChart';
import SiteAreaSelection from "./SiteAreaSelection";
import AllDeviceValuesTable from "./AllDeviceValuesTable";
import LatestActivitiesCard from "./LatestActivitiesCard";
import EnvValuesCard from "./EnvValuesCard";
import useDashboardSelection from "app/hooks/Sites/Dashboard/useDashboardSelection";

const DashBoard = () => {
  const {siteAreaID} = useDashboardSelection();
  return (
    <Container maxWidth="xl">
      <Stack gap={2}>
        <SiteAreaSelection />
        <DeviceCurrentValuesList />
        <Stack direction="row" alignItems="stretch" gap={2} sx={{ my: 2 }}>
          <DeviceValueChart />
          <Stack style={{flex: 1}} direction="column" >
            <LatestActivitiesCard/>
            {
              siteAreaID && siteAreaID === "62a6f8f5de5cae80f30fd490"
              ? <Box component={'img'} src='/crab.jpeg' sx={{ mt: 1, maxWidth: '100%' }} alt="hairy crab" /> : null
            }
            {
              siteAreaID && siteAreaID === "62a6f748de5cae80f30fd48d"
              ? <Box component={'img'} src='/perch.jpeg' sx={{ mt: 1, maxWidth: '100%' }} alt="jade perch" /> : null
            }
          </Stack>
          <EnvValuesCard/>
        </Stack>
        <AllDeviceValuesTable />
      </Stack>
    </Container>
  );
};

export default DashBoard;
