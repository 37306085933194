import React from 'react';
import { IconButton, Stack } from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// const InfoCardCarousel = ({ children }) => {
//   const settings = {
//     dots: false,
//     arrows: false,
//     infinite: false,
//     adaptiveHeight: true,
//     speed: 500,
//     slidesToShow: 5,
//     slidesToScroll: 1,
//   };
//   return (
//     <Slider {...settings}>
//       {children}
//     </Slider>
//   );
// };

const ScrollButton = ({
  onScroll,
  children,
}) => {
  const [timer, setTimer] = React.useState(0);

  const startTimer = React.useCallback(() => {
    onScroll();
    setTimer(setInterval(onScroll, 30));
  }, [onScroll]);
  const endTimer = React.useCallback(() => {
    clearInterval(timer);
  }, [timer]);

  return (
    <IconButton
      size="large"
      color="info"
      onMouseDown={startTimer}
      onMouseLeave={endTimer}
      onMouseUp={endTimer}
      onTouchStart={startTimer}
      onTouchEnd={endTimer}>
      {children}
    </IconButton>
  );
}
const InfoCardCarousel = ({ children }) => {
  const listRef = React.useRef();
  const scrollLeft = React.useCallback(() => {
    listRef.current?.scroll({ left: Math.max(listRef?.current?.scrollLeft - 114, 0), behavior: 'smooth' });
  }, []);
  const scrollRight = React.useCallback(() => {
    listRef.current?.scroll({ left: listRef?.current?.scrollLeft + 114, behavior: 'smooth' });
  }, []);

  return (
    <Stack direction="row" sx={{ width: '100%', my: 2 }} gap={1} alignItems='center'>
      <ScrollButton onScroll={scrollLeft}>
        <KeyboardArrowLeftIcon />
      </ScrollButton>
      <Stack
        ref={listRef}
        direction='row'
        flexWrap="nowrap"
        gap={2}
        sx={{
          flex: 1,
          overflowX: 'auto',
          py: 1,
          px: 0.5,
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': { display: 'none' },
        }}>
        {children}
      </Stack>
      <ScrollButton onScroll={scrollRight} >
        <KeyboardArrowRightIcon />
      </ScrollButton>
    </Stack>
  )
}

export default InfoCardCarousel;
