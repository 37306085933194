import { Link, useParams } from "react-router-dom";
import { AppBar, Box, Stack, Toolbar, Typography } from '@mui/material';
import SensetekLogoBlack from 'app/assets/Logo/sensetek_logo_horizontal_black.png';
import NotificationsIcon from '@mui/icons-material/Notifications';
import IconLink from "app/components/IconLink";

/* const groupProps = [
  {code:'SGP' ,name: 'Sino Group Properties'},
  {code:'SGP2' ,name: 'Sino Group Properties 2'},
] */

const SiteHeader = () => {
  const { id } = useParams();
  return (
    <AppBar position="relative" sx={{ bgcolor: 'white', px: 2, py: 1 }}>
      <Toolbar sx={{
        display: "flex",
        direction: "row",
        justifyContent: "space-between",
        bgcolor: 'white',
        width: '100%',
      }}>
        {/* todo: change to dropdown*/}
        <Stack spacing={3} direction="row" alignItems="center" sx={{ py: 1 }}>
          <Link to={"/"}>
            <Box component={'img'} src={SensetekLogoBlack} sx={{ maxHeight: '44px' }} alt="sensetekLogo" />
          </Link>
          <Typography color="black">Sensestek Group</Typography>
        </Stack>
        {/* todo: link to related path*/}
        <Stack spacing={3} direction="row" sx={{ py: 1 }}>
          <IconLink to={`/sites/${id}/analytics/activity?activeTab=alert`} variant="contained" title="Alerts"><NotificationsIcon /></IconLink>
          {/* todo: add red dot when there are Notifications */}
          {/* <Badge color="error" badgeContent="" variant="dot"> <NotificationsIcon /> </Badge> */}
        </Stack>
      </Toolbar>
    </AppBar>
  )
}

export default SiteHeader;
