import axios from 'app/helpers/axios';
import buildQuery from 'app/helpers/buildQuery';
import useSWR from 'swr';

const useSiteAreas = (siteID) => {
  const query = buildQuery({ form: { siteID }, sort: { key: 'name', asc: false } });
  const { data, isLoading } = useSWR(`/siteareas?${query}`, url => axios.get(axios.addApiUrl(url)).then(r => r.data));
  const state = {};
  state.init = !!data;
  state.submitting = isLoading;
  state.done = !isLoading;
  state.data = data?.items;
  state.page = data?.page;
  state.totalElements = data?.totalCount;
  state.totalPages = data?.pageCount;
  state.numberOfElements = data?.items?.length || 0;
  state.pageSize = data?.pageSize;

  return {
    state,
  }
}

export default useSiteAreas;