import {
  Paper,
  Modal,
  Stack,
  Typography,
  IconButton,
  TextField,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import React from "react";

const AddMemberModal = ({ open, toggleOpen, handleSubmit }) => {
  const [sensorGroups, setSensorGroups] = React.useState("all");
  const [role, setRole] = React.useState("admin");
  return (
    <Modal
      open={open}
      onClose={toggleOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper
        elevation={3}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          padding: 20,
          width: 400,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ marginBottom: 40 }}
        >
          <Typography variant="p" style={{ fontSize: 20, fontWeight: "bold" }}>
            Add Member
          </Typography>
          <IconButton onClick={() => toggleOpen()}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <form
          id="add-member-form"
          onSubmit={handleSubmit}
          style={{ marginTop: 10, marginBottom: 10 }}
        >
          <Stack direction="column" spacing={2}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Type email address / email domain here"
              fullWidth
            />
            <Button
              startIcon={<AddCircleIcon />}
              style={{ fontSize: 10, alignSelf: "self-start", marginTop: 0 }}
            >
              Email address/ Email Domain
            </Button>
            <Select
              labelId="sensor-groups-select-label"
              id="sensor-groups-select"
              value={sensorGroups}
              label={false}
              onChange={(event) => setSensorGroups(event.target.value)}
            >
              <MenuItem value="all">Select Site Areas</MenuItem>
              <MenuItem value="hsh-002">HSH-002</MenuItem>
              <MenuItem value="hsh-005">HSH-005</MenuItem>
            </Select>
            {/*<Select
              labelId="sensor-groups-select-label"
              id="sensor-groups-select"
              value={sensorGroups}
              label={false}
              onChange={(event) => setSensorGroups(event.target.value)}>
              <MenuItem value="all">All Sensor Groups</MenuItem>
              <MenuItem value="temparture">Temparture</MenuItem>
              <MenuItem value="do">DO</MenuItem>
              </Select>*/}
            <Select
              labelId="role-select-label"
              id="role-select"
              value={role}
              label={false}
              onChange={(event) => setRole(event.target.value)}
            >
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="viewer">Viewer</MenuItem>
            </Select>
            <Button
              startIcon={<AddCircleIcon />}
              style={{ fontSize: 10, alignSelf: "self-start", marginTop: 0 }}
            >
              Sensor Groups, Sensor
            </Button>
          </Stack>
          <Stack direction="row" justifyContent="flex-end">
            <Button
              variant="outlined"
              onClick={() => toggleOpen()}
              style={{ marginRight: 10 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="blueButton"
              onClick={handleSubmit}
            >
              Add Member
            </Button>
          </Stack>
        </form>
      </Paper>
    </Modal>
  );
};

export default AddMemberModal;
