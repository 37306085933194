import { Tab, Tabs, Typography, Box } from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import { useSearchParams } from "react-router-dom";

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
};
const NavBarItem = {
  'all': 0,
  'alert': 1,
  'setting': 2,
}

const TabCardList = ({ tabHeading = [], tabContent = [], renderCard }) => {
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get('activeTab');
  const [tabValue, setTabValue] = React.useState(NavBarItem[activeTab] || 0);
  const handleTabChange = React.useCallback((event, newValue) => {
    setTabValue(newValue);
  }, []);
  console.log();
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          {tabHeading.map((heading) => {
            return <Tab label={heading.label} {...a11yProps(heading.key)} />;
          })}
        </Tabs>
      </Box>
      {tabContent.map((content, key) => {
        const grouped = Object.values(
          content.data.reduce((a, o) => {
            const date = DateTime.fromSeconds(parseInt(o.timestamp));
            const formatDate = date.startOf('days').toFormat('yyyy-MM-dd');
            (a[formatDate] ??= []).push({ ...o });
            a[formatDate] = a[formatDate] ?? [];
            return a;
          }, {}));

        return (
          <TabPanel value={tabValue} index={key}>
            {grouped.map((group) => {
              const groupDate = DateTime.fromSeconds(parseInt(group[0].timestamp));
              const compareNow = Math.abs(groupDate.diffNow('days').days);
              let groupHeading = '';
              if (compareNow < 1) {
                // today 
                groupHeading = 'Today';
              } else if (compareNow > 1 && compareNow < 2) {
                // yesterday
                groupHeading = 'Yesterday';
              } else {
                // origianl
                groupHeading = groupDate.toFormat("yyyy-MM-dd");
              }
              return (
                <Box>
                  <Typography sx={{ marginTop: 2, marginBottom: 2 }}>{groupHeading}</Typography>
                  {group.map(item => renderCard(item))}
                </Box>
              )
            })}
          </TabPanel>
        );
      })}
    </>
  );
};

export default TabCardList;
