import { MenuItem, Paper, Stack, Typography } from "@mui/material";
import OutlinedTextField from "app/components/form/outlined/OutlinedTextField";
import useDashboardSelection from "app/hooks/Sites/Dashboard/useDashboardSelection";
import useSiteAreas from "app/hooks/Sites/Dashboard/useSiteAreas";
import { DashboardSelectionActions } from "app/redux/SiteDashboard/Selection";
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const SiteAreaSelection = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { state: { data: options }, } = useSiteAreas(id);
  const { siteAreaID } = useDashboardSelection();
  const handleChange = React.useCallback((e) => {
    dispatch(DashboardSelectionActions.selectSiteArea(e.target.value));
  }, [dispatch]);

  const selectedSiteArea = (options || [])?.find((opt) => opt._id === siteAreaID);
  // console.log(options);
  return (
    <Stack direction="row" gap={2} alignItems="center">
      <OutlinedTextField
        label="Select Area"
        select
        name="siteAreaID"
        value={siteAreaID || ""}
        sx={{ width: 200, backgroundColor: "white", borderRadius: 1 }}
        onChange={handleChange}
      >
        <MenuItem value="">--Select Area--</MenuItem>
        {options?.filter(r => r.name !== 'HSH-Env') // hard code remove HSH-Env on demo
          .map((opt) => (
            <MenuItem key={opt._id} value={opt._id}>
              {opt.name}
            </MenuItem>
          ))}
      </OutlinedTextField>
      <Stack direction="row" spacing={2}>
        {selectedSiteArea?.species
          ? selectedSiteArea?.species?.map((r, index) => {
            return (
              <Stack direction="row" sx={{ borderRadius: 2, py: 1, width: 250 }} alignItems='center' component={Paper} key={`${r.name}-${index}`}>
                <Typography variant="h6" sx={{ px: 2, flex: 1 }}>{r.name}</Typography>
                <Typography sx={{ px: 2 }}><small>(Count: {r.count})</small></Typography>
              </Stack>
            );
          })
          : null}
      </Stack>
    </Stack>
  );
};

export default SiteAreaSelection;
