import { asNumber } from "app/helpers/formatter";


export function renderDeviceValue({ key = '', value = '' } = {}) {
  if (!value) {
    return null; // value is empty, fail fast
  }

  switch (key) {
    case 'waterlevel':
      return `${asNumber(value, { trimTailingZero: true })} cm`;
    case 'battery':
      return `${asNumber(value, { trimTailingZero: true })}`;
    case 'temperature':
      return `${asNumber(value, { decimal: 1 })} °C`;
    case 'tds':
      return `${asNumber(value, { decimal: 1, trimTailingZero: true })} ppm`;
    case 'do':
      return `${asNumber(value, { decimal: 2, trimTailingZero: true })} mgL`;
    case 'ph':
      return asNumber(value, { decimal: 2, trimTailingZero: true });
    case 'humidity':
      return `${asNumber(value, { decimal: 2 })} %`;
    default:
      // console.log(Number.isNaN(Number(value)), value);
      return Number.isNaN(Number(value)) ? value : asNumber(value);
  }
}

export const getUnit = (valueType) => {
  switch (valueType) {
    case 'waterlevel':
      return `cm`;
    case 'temperature':
      return `°C`;
    case 'tds':
      return `ppm`;
    case 'do':
      return `mgL`;
    case 'humidity':
      return '%';
    default:
      return '';
  }
}