import React from 'react';
import Header from './Header';
import { Stack } from '@mui/material';

export default function MainLayout({ children }) {
  return ( // Common Layout
    <Stack sx={{ width: '100%', bgcolor: '#F4F6F8', height: '100vh' }}>
      <Header />
      <main id="main">
        {children}
      </main>
    </Stack>
  );
}