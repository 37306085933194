import React from "react";
import {
  Stack,
  Typography,
  Box,
  Card,
  CardContent,
  Container,
} from "@mui/material";
import CheckboxButton from "app/components/form/CheckboxButton";
import SimpleDatePicker from "app/components/form/SimpeDatePicker";
import TabCardList from "./TabCardList";
import { DateTime } from "luxon";
import Helmet from "react-helmet";

const GROUP_OPTIONS = [
  {
    name: "Personal Sensor Group 1",
    code: "senor_1",
  },
  {
    name: "Personal Sensor Group 2",
    code: "senor_2",
  },
  {
    name: "Personal Sensor Group 3",
    code: "senor_3",
  },
];

const SENOR_OPTIONS = [
  {
    name: "Tempature",
    code: "tempature",
  },
  {
    name: "DO",
    code: "do",
  },
  {
    name: "Ph",
    code: "ph",
  },
];

const ActivityFilter = () => {
  return (
    <Stack direction="row">
      <CheckboxButton
        data={GROUP_OPTIONS}
        buttonLabel="Select Sensor Groups"
        checkMasterLabel="All Sensor Groups"
        buttonProps={{
          variant: "outlined",
          sx: { backgroundColor: "#fff", color: "#333" },
        }}
      />
      <CheckboxButton
        data={SENOR_OPTIONS}
        buttonLabel="Select Sensor"
        checkMasterLabel="All Sensors"
        buttonProps={{
          variant: "outlined",
          sx: { backgroundColor: "#fff", color: "#333" },
        }}
      />
      <SimpleDatePicker label="From" inputProps={{}} />
      <SimpleDatePicker label="To" inputProps={{}} />
    </Stack>
  );
};

const ACTIVITY_DATA = [
  {
    _id: "1",
    timestamp: "1653897129",
    site: {
      _id: "1",
      name: "City Walk",
      tag: "N.T.",
    },
    location: "Floor B1",
    type: "alert",
    message: "Temp: 33°C  -  Temperature too high",
  },
  {
    _id: "2",
    timestamp: "1653897129",
    site: {
      _id: "1",
      name: "City Walk",
      tag: "N.T.",
    },
    location: "Parameter",
    type: "setting",
    message: "Tiphanie Chong changed the highest Temperature to 30°C",
  },
  {
    _id: "3",
    timestamp: "1653810729",
    site: {
      _id: "1",
      name: "City Walk",
      tag: "N.T.",
    },
    location: "Parameter",
    type: "setting",
    message: "Tiphanie Chong changed the highest Temperature to 30°C",
  },
  {
    _id: "4",
    timestamp: "1653810729",
    site: {
      _id: "1",
      name: "City Walk",
      tag: "N.T.",
    },
    location: "Parameter",
    type: "setting",
    message: "Tiphanie Chong changed the highest Temperature to 30°C",
  },
  {
    _id: "5",
    timestamp: "1653810729",
    site: {
      _id: "1",
      name: "City Walk",
      tag: "N.T.",
    },
    location: "Floor C1",
    type: "alert",
    message: "Tiphanie Chong changed the highest Temperature to 30°C",
  },
  {
    _id: "6",
    timestamp: "1653551529",
    site: {
      _id: "1",
      name: "City Walk",
      tag: "N.T.",
    },
    location: "Floor C1",
    type: "alert",
    message: "Temp: 33°C  -  Temperature too high",
  },
];

const MainActivity = () => {
  const renderCard = React.useCallback((item) => {
    const date = DateTime.fromSeconds(parseInt(item.timestamp));
    const isToday = Math.abs(date.diffNow("days")) < 1;
    const isYesterday =
      Math.abs(date.diffNow("days")) < 2 && Math.abs(date.diffNow("days")) > 1;
    let shownDate = date.toFormat("yyyy-MM-dd hh:mm");
    if (isToday) {
      shownDate = "Today";
    }
    if (isYesterday) {
      shownDate = "Yesterday";
    }
    return (
      <Card sx={{ padding: 2, mb: 2 }}>
        <CardContent>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography>{shownDate}</Typography>
              <Typography>{item.site.name}</Typography>
              <Typography>
                <span>{item.location}</span>
                <span>{item.message}</span>
              </Typography>
            </Box>
            <Box>
              <Typography>{item.type}</Typography>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    );
  }, []);
  return (
    <Container
      sx={{ mt: 10, backgroundColor: "rgb(244, 246, 248)" }}
      maxWidth={false}
    >
      <Helmet title="Activity" />
      <Container maxWidth="lg" sx={{ backgroundColor: "rgb(244, 246, 248)" }}>
        <Typography variant="h4" mb={2}>
          Activity
        </Typography>
        <Box sx={{ maxWidth: "100%" }}>
          <ActivityFilter />
        </Box>
        <TabCardList
          tabHeading={[
            { label: "All", key: "0" },
            { label: "Alert", key: "1" },
            { label: "Settings", key: "2" },
          ]}
          tabContent={[
            { data: ACTIVITY_DATA },
            { data: ACTIVITY_DATA.filter((data) => data.type === "alert") },
            { data: ACTIVITY_DATA.filter((data) => data.type === "setting") },
          ]}
          renderCard={renderCard}
        />
      </Container>
    </Container>
  );
};
export default MainActivity;
