import useSWR from 'swr';
import axios from 'app/helpers/axios';
import buildQuery from 'app/helpers/buildQuery';

const transformResponse = (data) => {
  return data?.items || [];
}

const useGetLatestActvitiesQuery = ({ siteID, siteAreaID }) => {
  const queryStr = buildQuery({ form: { siteID, siteareaID: siteAreaID }, sort: { key: 'createdAt', asc: false }, page: 0, pageSize: 3 });
  const url = `/siteactivity?${queryStr}`;
  return useSWR(url, () => axios.get(axios.addApiUrl(url)).then(r => r.data).then(transformResponse), {
    focusThrottleInterval: 30000,
    refreshInterval: 60000
  });
}

export default useGetLatestActvitiesQuery;