import { flatMap } from 'lodash';
import useSiteAreas from './useSiteAreas';
import useSWR from 'swr';
import buildQuery from 'app/helpers/buildQuery';
import axios from 'app/helpers/axios';
import laggy from 'app/helpers/laggy';
import { useParams } from 'react-router';

const useDeviceSettings = () => {
  const { id } = useParams();
  const { state: siteAreasState } = useSiteAreas(id);
  const siteAreas = siteAreasState.data;
  const query = siteAreas ? buildQuery({ form: { placeholderID: flatMap(siteAreas, 'placeholderIDs') } }) : null;
  const { data, isLoading } = useSWR(query ? `/devicesetting?${query}` : null, (url) => axios.get(axios.addApiUrl(url)).then(r => r.data), { use: [laggy] });
  const state = {};
  state.init = !data;
  state.submitting = isLoading;
  state.done = !isLoading;
  state.data = data?.items;
  state.page = data?.page;
  state.totalElements = data?.totalCount;
  state.totalPages = data?.pageCount;
  state.numberOfElements = data?.items?.length || 0;
  state.pageSize = data?.pageSize;
  return state;
}

export default useDeviceSettings;