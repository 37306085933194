import { filter, uniqWith, isEqual } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  siteAreaID: null,
  deviceValue: [],
};

// TODO: clear on site changes
const SiteDashboardSlicer = createSlice({
  name: 'SiteDashboard/Selection',
  initialState,
  reducers: {
    selectSiteArea: (state, { payload }) => {
      state.siteAreaID = payload;
      // clear selected deviceValue
      state.deviceValue = [];
    },
    selectDeviceValue: (state, { payload: { deviceID, valueType } }) => {
      state.deviceValue = uniqWith(state.deviceValue.concat({ deviceID, valueType }), isEqual);
    },
    deselectDeviceValue: (state, { payload: { deviceID, valueType } }) => {
      state.deviceValue = filter(state.deviceValue, a => a.deviceID !== deviceID || a.valueType !== valueType);
    },
    clear: () => initialState,
  },
})

export const { selectSiteArea, selectDeviceValue, deselectDeviceValue } = SiteDashboardSlicer.actions;
export const DashboardSelectionActions = { selectSiteArea, selectDeviceValue, deselectDeviceValue };
export default SiteDashboardSlicer.reducer;