import { Typography } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet';


const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>Not Found (#404)</title>
      </Helmet>
      <Typography variant='h5' component="h1">Not Found (#404)</Typography>
      <Typography>
        Page not found.
      </Typography>
    </>
  );
}

export default NotFound;