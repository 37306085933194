import React from 'react';
import useLoggedIn from 'app/hooks/useLoggedIn';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setRedirectTo } from 'app/redux/account';

const RestrictedAccess = ({
  children,
  to = '/login',
  reverse = false,
}) => {
  const dispatch = useDispatch();
  const loggedIn = useLoggedIn();
  const navigate = useNavigate();
  const location = useLocation();
  const restricted = !(Boolean(loggedIn) !== Boolean(reverse));
  React.useEffect(() => {
    if (restricted) {
      if (!loggedIn) { // save the last visit URL
        // console.log(location.pathname);
        dispatch(setRedirectTo(location.pathname));
      }
      // console.log(to);
      navigate(to, { replace: true });
    }
  }, [to, navigate, restricted, loggedIn, location.pathname, dispatch])
  if (restricted) {
    // console.log('restricted');
    return null;
  }
  return children;
}

export default RestrictedAccess;