import React from 'react';
import { Routes, Route, useLocation, /*Navigate, useNavigate*/ } from 'react-router-dom';

import MainLayout from './layouts/MainLayout';
import Login from 'app/containers/Login';
import NotFound from './containers/NotFound';
import RestrictedAccess from './components/RestrictedAccess';
import Home from './containers/Home';
import MainActivity from './containers/Home/MainActivity';

import DashBoard from './containers/Sites/Analytics/Dashboard';
import Activity from './containers/Sites/Analytics/Activity';
import LiveCam from './containers/Sites/Analytics/LiveCam';
import Report from './containers/Sites/Analytics/Report';
import Members from './containers/Sites/Settings/Members';
import DashboardSettings from './containers/Sites/Settings/DashboardSettings';
import ParameterDetails from './containers/Sites/Settings/ParameterDetails';
import SiteLayout from './layouts/SiteLayout';
import MyAccount from './containers/Home/MyAccount';


const useScrollToTop = () => {
    const { pathname } = useLocation();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return { pathname };
}


export default function ApplicationRoutes() {
    useScrollToTop();
    return (
        <>
            <Routes>
                <Route exact path="/" element={<MainLayout><RestrictedAccess><Home /></RestrictedAccess></MainLayout>} />
                <Route exact path="/login" element={<MainLayout><RestrictedAccess to="/" reverse><Login /></RestrictedAccess></MainLayout>} />
                <Route exact path="/activity" element={<MainLayout><RestrictedAccess><MainActivity /></RestrictedAccess></MainLayout>} />
                <Route exact path="/my-account" element={<MainLayout><RestrictedAccess><MyAccount /></RestrictedAccess></MainLayout>} />
                <Route path="/sites/:id" element={<SiteLayout />}>
                    <Route path="analytics/dashboard" element={<RestrictedAccess><DashBoard /></RestrictedAccess>} />
                    <Route exact path="analytics/activity" element={<RestrictedAccess><Activity /></RestrictedAccess>} />
                    <Route exact path="analytics/livecam" element={<RestrictedAccess><LiveCam /></RestrictedAccess>} />
                    <Route exact path="analytics/report" element={<RestrictedAccess><Report /></RestrictedAccess>} />
                    <Route exact path="settings/members" element={<RestrictedAccess><Members /></RestrictedAccess>} />
                    <Route exact path="settings/dashboard-settings" element={<RestrictedAccess><DashboardSettings /></RestrictedAccess>} />
                    <Route exact path="settings/parameter-details" element={<RestrictedAccess><ParameterDetails /></RestrictedAccess>} />
                </Route>
                <Route path="*" element={<MainLayout><NotFound /></MainLayout>} />
            </Routes >
        </>
    );
}