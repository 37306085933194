import React from "react";
import { Drawer, Box, Stack, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import RestoreIcon from '@mui/icons-material/Restore';
import BarChartIcon from '@mui/icons-material/BarChart';
// import LiveCamIcon from '@mui/icons-material/VideoLibraryTwoTone';
// import MembersIcon from '@mui/icons-material/PersonOutlineTwoTone';
import SettingsIcon from '@mui/icons-material/SettingsTwoTone';
// import ParameterIcon from '@mui/icons-material/ImportExport';
// import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import MaxCollapseIcon from '@mui/icons-material/ArrowForwardIos';
import MinCollapseIcon from '@mui/icons-material/ArrowBackIosNew';
import { NavLink, useParams } from "react-router-dom";
import useSiteView from "app/hooks/Sites/useSiteView";

const Circle = ({
  sx = {},
  //status = "inactive"
  active = false
}) => {
  const getStatusColor = () => { return !active ? 'red' : '#00C064'; };
  return (
    <Box sx={{ borderRadius: '50%', bgcolor: getStatusColor, width: 16, height: 16, ...sx }} />
  );
}

const StyledListItem = ({ open, sx = {}, ...props }) => <ListItem sx={{ pl: open ? 4 : 1, pr: open ? 3.375 : 1, ...sx }} {...props} />;

const SiteInfo = ({ open, siteData }) => {
  // console.log(siteData);
  if (!open) {
    return (
      <Stack alignItems="center" open={open} sx={{ pl: 1, pr: 1, mt: 10, mb: 7.5 }}>
        <Circle active={siteData?.active} />
      </Stack>
    );
  }
  return (
    <Box open={open} sx={{ pl: 4, pr: 3.375, mt: 10, mb: 7.5 }}>
      <Stack>
        <Typography sx={{ color: '#DFE3E8' }}>{siteData?.tags?.join(', ') || ''}</Typography>
        <Stack direction="row" alignItems="center" sx={{ width: '100%' }}>
          <Typography sx={{ fontWeight: 700, lineHeight: 1.5 }}>{siteData?.name}</Typography>
          <Circle active={siteData?.active || true} sx={{ ml: 2 }} />
        </Stack>
      </Stack>
    </Box>
  );
}

const ListItemTitle = ({ title, open }) => {
  return (
    <StyledListItem open={open}>
      <ListItemText sx={{ color: '#DFE3E8', '& .MuiTypography-root': { fontSize: '0.875rem', display: open ? 'block' : 'none' } }}>
        {open && title}
      </ListItemText>
    </StyledListItem>
  );
}

const DrawerCollapseButton = ({
  open = {},
  onClick = {},
}) => {
  return (
    <Box component={Stack}
      alignItems="center"
      justifyContent="center"
      onClick={onClick}
      sx={{ position: 'absolute', bottom: 80, right: 0, bgcolor: '#919EAB', cursor: 'pointer', width: 30, height: 30 }}>
      {open ? <MinCollapseIcon /> : <MaxCollapseIcon />}
    </Box>
  )
}

const DrawerButton = ({
  open = {},
  icon = {},
  to = "",
  text = '',
}) => {
  return (
    <StyledListItem open={open} key={text} >
      <ListItemButton component={NavLink} to={to} sx={{ borderRadius: 2, p: 1.125, '&.active': { bgcolor: '#0AB2AA' } }}>
        <ListItemIcon sx={{
          color: 'inherit',
          mr: open ? 1.5 : 0,
          minWidth: 0,
          justifyContent: 'center',
        }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText primary={text} sx={{ display: open ? 'block' : 'none', m: 0, '& .MuiTypography-root': { color: '#F4F6F8' } }} />
      </ListItemButton>
    </StyledListItem>
  )
}

const SiteDrawer = () => {
  const [open, setOpen] = React.useState(true);
  const handleToogle = () => setOpen(!open);
  const { id } = useParams();
  const { data } = useSiteView(id);

  return (
    <>
      <Drawer
        open
        variant="permanent"
        sx={{
          position: 'relative',
          width: open ? 280 : 57,
          transition: '0.3s', // hardcode width for transition
          '& .MuiPaper-root': {
            bgcolor: '#454F5B',
            color: 'white',
            width: open ? 280 : 57,
            transition: '0.3s',
          }, // hardcode width for transition
        }}>
        <SiteInfo open={open} siteData={data} />
        <List>
          <ListItemTitle open={open} title="Analytics" />
          <DrawerButton open={open} text='Activity' icon={<RestoreIcon />} to={`/sites/${id}/analytics/activity`} />
          <DrawerButton open={open} text='Dashboard' icon={<BarChartIcon />} to={`/sites/${id}/analytics/dashboard`} />
          {/*<DrawerButton open={open} text='Live Cam' icon={<LiveCamIcon />} to={`/sites/${id}/analytics/livecam`} />
          <DrawerButton open={open} text='Report' icon={<ContentPasteIcon />} to={`/sites/${id}/analytics/report`} /> {/* Can't find the icon */}
        </List>
        <List>
          <ListItemTitle open={open} title="Settings" />
          {/*<DrawerButton open={open} text='Members' icon={<MembersIcon />} to={`/sites/${id}/settings/members`} />*/}
          <DrawerButton open={open} text='Dashboard Settings' icon={<SettingsIcon />} to={`/sites/${id}/settings/dashboard-settings`} />
          {/*<DrawerButton open={open} text='Parameter Details' icon={<ParameterIcon />} to={`/sites/${id}/settings/parameter-details`} />*/}
        </List>
        <DrawerCollapseButton open={open} onClick={handleToogle} />
      </Drawer>
    </>
  )
}

export default SiteDrawer;