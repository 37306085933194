import React, { useState } from 'react';
import useSWR from 'swr';
import axios from 'app/helpers/axios';
import buildQuery from 'app/helpers/buildQuery';

const useSites = () => {
  const [page, setPage] = useState(0);
  const query = buildQuery({ page, sort: { key: 'date', asc: false } });
  const { data, isLoading, error, mutate } = useSWR(`/sites?${query}`, (url) => axios.get(axios.addApiUrl(url)).then(r => r.data));
  const state = {};
  state.init = !!data;
  state.submitting = isLoading;
  state.done = !isLoading;
  state.data = data?.items;
  state.page = data?.page;
  state.totalElements = data?.totalCount;
  state.totalPages = data?.pageCount;
  state.numberOfElements = data?.items?.length || 0;
  state.pageSize = data?.pageSize;
  const handleRefresh = React.useCallback(() => { mutate(`/sites?${query}`) }, [mutate, query]);
  return {
    state,
    error,
    handleRefresh,
    handlePageChange: setPage,
  };
};

export default useSites;
