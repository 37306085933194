import React from 'react';
import { TextField } from '@mui/material';
import { useController } from 'react-hook-form';

const ControlledTextField = ({
  control,
  name,
  defaultValue,
  rules,
  shouldUnregister,
  helperText,
  color = "primary",
  TextFieldComponent = TextField,
  ...props
}) => {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { invalid, error },
    // formState: { touchedFields, dirtyFields }
  } = useController({ control, name, defaultValue, rules, shouldUnregister });

  return (
    <TextFieldComponent
      {...props}
      onChange={onChange}
      onBlur={onBlur}
      name={name}
      value={value}
      inputRef={ref}
      error={invalid}
      color={color}
      helperText={error?.message || helperText}
    />
  );
}

export default ControlledTextField;