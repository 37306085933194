import React from 'react';
import { Typography, Divider, Stack, Card, CardMedia, CardActions, Button } from '@mui/material';
import { Box, Container } from '@mui/system';
import useJoiForm from 'app/helpers/useJoiForm';
import FieldRow from 'app/components/common/FieldRow';
import FilledTextField from 'app/components/form/FilledTextField';
import DummyPic from 'app/assets/Logo/dummyPic/fack-layout.jpg'

// page padding left "32px"
const SettingSection = ({
  title,
  children,
  ...props }) => {
  return (
    <Container>
      <Box sx={{ py: 2 }}>
        <Typography variant="h4" component="h1" sx={{ fontWeight: 900 }}>{title}</Typography>
      </Box>
      {children}
    </Container>
  )
};

const DashboardSettingsForm = () => {
  //copy from login page--- not yet done
  const handleSubmit = React.useCallback(async (values) => {
    console.log(values);
  }, []);

  const { control, onFormSubmit, } = useJoiForm({
    onSubmit: handleSubmit,
  });
  //copy from login page--- not yet done

  return (
    //  page heading
    <SettingSection title={'Dashboard Settings'}>
      {/* spacing to be confirm */}
      <Box component="form" onSubmit={onFormSubmit} sx={{ py: 3, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Stack spacing={4} sx={{ width: '100%', mr: '60px' }}>
          <FieldRow>
            <FilledTextField
              control={control}
              name="DashboardName"
              label="Dashboard Name"
              variant="filled"
              rules={{ required: 'Required.' }}
              fullWidth
              sx={{
                '& .MuiFilledInput-root .MuiFilledInput-input': {
                  height: '50px',
                }
              }}
            />
          </FieldRow>
          <FieldRow>
            <FilledTextField
              control={control}
              name="SiteLocation"
              label="Site Location"
              variant="filled"
              rules={{ required: 'Required.' }}
              fullWidth
              sx={{
                '& .MuiFilledInput-root .MuiFilledInput-input': {
                  height: '50px',
                }
              }}
            />
          </FieldRow>
        </Stack>
        <Box src={DummyPic} component={'img'} sx={{ maxWidth: '400px' }} alt="site-location" />
      </Box>
    </SettingSection>

  )
};

const DashboardTheme = () => {
  return (
    <SettingSection title={'Dashboard Theme & Layout'}>
      <Card>
        <CardMedia
          component="img"
          height="140"
          src={DummyPic}
          alt="green iguana"
        />
        <CardActions>
          <Button size="small">Default</Button>
        </CardActions>
      </Card>
    </SettingSection>
  )
};


const Dashboard = () => {

  return (
    <Container>
      <DashboardSettingsForm />
      <Divider />
      <DashboardTheme />
    </Container>
  );
};
export default Dashboard;
