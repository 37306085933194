import React from 'react';
import { useForm } from "react-hook-form";

const convertError = (joiErrors) => {
  // console.log(joiErrors);
  const errors = {};
  joiErrors.forEach((r) => {
    const field = r.path?.[0] || '_error';
    errors[field] = r.message;
  });
  return errors;
};

const handle422 = (e) => {
  if (e?.response?.status === 422) {
    // console.log(convertError(e.response.data));
    return convertError(e.response.data);
  }
  // console.log(e);
  return { '_error': e.toString() };
};

function addServerErrors(errors = {}, setError) {
  return Object.keys(errors).forEach((key) => {
    setError(key, {
      type: "server",
      message: errors[key],
    });
  });
};

const useJoiForm = ({
  onSubmit = () => { },
  onError,
  ...props
} = {}) => {
  const { handleSubmit, setError, clearErrors, ...form } = useForm(props);

  const wrapOnSubmit = handleSubmit(React.useCallback(async values => {
    try {
      return await onSubmit(values);
    } catch (e) {
      const errors = handle422(e);
      addServerErrors(errors, setError);
      onError?.(errors, e);
    }
  }, [onSubmit, onError, setError]));

  return {
    ...form,
    setError,
    onFormSubmit: (e) => {
      clearErrors()
      wrapOnSubmit(e);
    },
  };
}

export default useJoiForm;
