import useSWR from 'swr';
import axios from 'app/helpers/axios';
import sum from 'lodash/sum';

const transformResponse = (data) => {
  const items = data?.items || [];
  const total = sum(items.map(r => Number(r.value))) || 0;
  const average = total / Math.max(items?.length, 1);
  return {
    current: data?.items[0] || {},
    average,
  }
}

const useGetLatestValueQuery = ({ deviceID, valueType }) => {
  const url = deviceID && valueType ? `/devicevalue/${deviceID}/${valueType}?sort=-createdAt&pageSize=5` : null;
  return useSWR(url, () => axios.get(axios.addApiUrl(url)).then(r => r.data).then(transformResponse), {
    focusThrottleInterval: 30000,
    refreshInterval: 60000
  });
}

export default useGetLatestValueQuery;