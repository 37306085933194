import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Tooltip, IconButton } from '@mui/material';

const IconLink = ({ title, ...props }) => (
  <Tooltip title={title}>
    <IconButton component={Link} aria-label={title} {...props} />
  </Tooltip>
);
IconLink.propTypes = {
  title: PropTypes.string.isRequired,
};

export default IconLink;