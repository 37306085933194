import { KeyboardArrowDown } from "@mui/icons-material";
import { Button, Checkbox, MenuItem, Popover } from "@mui/material";
import React from "react";

const CheckboxButton = ({
  data = [],
  buttonLabel = '',
  checkMasterLabel = '',
  buttonProps = {},
  sx = {}, 
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const isSelected = React.useCallback(
    (code) => selectedOptions.findIndex((ele) => ele.code === code) >= 0,
    [selectedOptions]
  );

  const handledashboardsChange = React.useCallback(
    (data, optionSelected) => {
      if (optionSelected) {
        setSelectedOptions(
          selectedOptions.filter((ele) => ele.code !== data.code)
        );
      } else {
        setSelectedOptions(selectedOptions.concat([data]));
      }
    },
    [selectedOptions]
  );

  const onSelectAllClick = () => {
    if (selectedOptions.length < data.length) {
      setSelectedOptions(data.slice());
    } else {
      setSelectedOptions([]);
    }
  }

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <Button
        aria-describedby={id}
        variant="contained"
        color="btnPrimary"
        onClick={handleClick}
        endIcon={<KeyboardArrowDown />}
        style={{ marginRight: 10 }}
        {...buttonProps}
      >
        {selectedOptions.length === 0 ? buttonLabel :
          (selectedOptions.length === data.length ? checkMasterLabel : selectedOptions.map(option => option.name).join(', '))}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem key="option-master">
          <Checkbox
            indeterminate={
              selectedOptions.length > 0 &&
              selectedOptions.length < data.length
            }
            checked={
              selectedOptions.length > 0 &&
              selectedOptions.length === data.length
            }
            onChange={onSelectAllClick}
          />
          {checkMasterLabel}
        </MenuItem>
        {data.map((option) => {
          const optionSelected = isSelected(option.code);
          return (
            <MenuItem key={option.code}>
              <Checkbox
                checked={optionSelected}
                onChange={() => handledashboardsChange(option, optionSelected)}
              />
              {option.name}
            </MenuItem>
          );
        })}
      </Popover>
    </>
  );
};

export default CheckboxButton;
