import { combineReducers } from '@reduxjs/toolkit';
import common from './common';
import account from './account';
import SiteDashboard from './SiteDashboard';

const rootReducer = combineReducers({
  common,
  account,
  SiteDashboard,
});
export default rootReducer;