import { TextField } from '@mui/material';
import React from 'react';

const OutlinedTextField = ({
  sx = {},
  ...props
}) => {
  return (
    <TextField
      variant="outlined"
      sx={{
        borderRadius: 2,
        '& .MuiOutlinedInput-notchedOutline': {
          borderRadius: 2,
          borderColor: 'grey.outline',
          borderWidth: 1,
          borderStyle: 'solid',
        },
        ...sx
      }}
      {...props}
    />
  )
}
export default OutlinedTextField;