import React from 'react';
import SitesHeader from './Sites/Header';
import SitesDrawer from './Sites/Drawer';
import { Box, Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';

export default function SiteLayout() {
  return ( // Site Layout
    <Stack direction="row" flexWrap='nowrap' spacing={0} sx={{ width: '100%', height: '100vh' }} >
      <SitesDrawer />
      <Stack direction="column" sx={{ flex: 1 }} >
        <SitesHeader />
        <Box component="main" id="main" sx={{ p: 4, flex: 1, bgcolor: 'grey.50' }}>
          <Outlet />
        </Box>
      </Stack>
    </Stack>
  );
}