import { Box, Paper, Stack, Typography } from '@mui/material';
import useGetLatestActvitiesQuery from 'app/hooks/Sites/Dashboard/useGetLatestActvitiesQuery';
import useDashboardSelection from 'app/hooks/Sites/Dashboard/useDashboardSelection';
import React from 'react';
import { useParams } from 'react-router-dom';
import useDeviceSettings from 'app/hooks/Sites/Dashboard/useDeviceSettings';


const DeviceName = ({ deviceName, deviceID }) => {
  const { data } = useDeviceSettings();
  return (
    deviceName ||
    data?.find((r) => r.deviceID === deviceID)?.deviceName ||
    deviceID
  );
};

const ActivityRow = ({ activity }) => {
  return (
    <Box>
      <Typography fontWeight="700">
        [{activity.activityType}] {activity.siteAreaName || ""}{" "}
        <DeviceName
          deviceID={activity.deviceID}
          deviceName={activity.deviceName}
        />
      </Typography>
      <Typography>{activity.content}</Typography>
    </Box>
  );
};
const LatestActivitiesCard = () => {
  const { id: siteID } = useParams();
  const { siteAreaID } = useDashboardSelection();
  const { data = [], isLoading } = useGetLatestActvitiesQuery({ siteID, siteAreaID });
  return (
    <Paper sx={{ borderRadius: 2, py: 2, width: 400, flex: 1 }}>
      <Typography variant="h6" gutterBottom sx={{ px: 2 }}>Recent Activity</Typography>
      <Stack direction="column" gap={2} sx={{ p: 2, height: '250px', overflowY: 'auto' }}>
        {data?.map(activity => <ActivityRow key={activity._id} activity={activity} />)}
      </Stack>
      {(!isLoading && !data?.length) && <Typography sx={{ fontStyle: 'italic', px: 2 }}>No recent activites.</Typography>}
    </Paper>
  );
};
export default LatestActivitiesCard;
/*
<Divider />
<Box sx={{ justifyContent: 'flex-end', display: 'flex', px: 2, pt: 2 }}>
  <Button sx={{ textTransform: 'none' }} variant="text" endIcon={<KeyboardArrowRight />} component={Link} to={`/sites/${siteID}/analytics/activity`}>View All</Button>
</Box>
*/
