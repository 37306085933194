import useSWR from 'swr';
import axios from 'app/helpers/axios';
import buildQuery from 'app/helpers/buildQuery';

const transformResponse = (data) => {
  return data?.items || [];
}

const useGetDeviceValuesQuery = ({ placeholderIDs }) => {
  const url = placeholderIDs && placeholderIDs.length ? `/devicevalue?${buildQuery({ form: { placeholderID: placeholderIDs }, sort: { key: 'createdAt', asc: false }, page: 0, pageSize: 20 })}` : null;
  return useSWR(url, () => axios.get(axios.addApiUrl(url)).then(r => r.data).then(transformResponse), {
    focusThrottleInterval: 30000,
    refreshInterval: 60000
  });
}

export default useGetDeviceValuesQuery;