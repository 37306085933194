import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import axios from 'app/helpers/axios';

const useSiteView = (id) => {
  const navigate = useNavigate();
  const { data, isLoading } = useSWR(`/sites/${id}`, (url) => axios.get(axios.addApiUrl(url)).then(r => r.data), {
    onError: (e, key) => {
      console.error(`failed to fetch ${key}`, e);
      navigate('/404', { replace: true });
    }
  });
  const state = {};
  state.data = data;
  state.loading = isLoading;
  state.init = !!data;  
  return state;
}

export default useSiteView;