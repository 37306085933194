import React from "react";
import { Stack, Typography, Box, Card, CardContent } from "@mui/material";
import CheckboxButton from "app/components/form/CheckboxButton";
import SimpleDatePicker from "app/components/form/SimpeDatePicker";
import TabCardList from "./TabCardList";
import { DateTime } from "luxon";

const GROUP_OPTIONS = [
  {
    name: "Personal Sensor Group 1",
    code: "senor_1",
  },
  {
    name: "Personal Sensor Group 2",
    code: "senor_2",
  },
  {
    name: "Personal Sensor Group 3",
    code: "senor_3",
  },
];

const SENOR_OPTIONS = [
  {
    name: "Tempature",
    code: "tempature",
  },
  {
    name: "DO",
    code: "do",
  },
  {
    name: "Ph",
    code: "ph",
  },
];

const ActivityFilter = () => {
  return (
    <Stack direction="row" >
      <CheckboxButton
        data={GROUP_OPTIONS}
        buttonLabel="Select Sensor Groups"
        checkMasterLabel="All Sensor Groups"
        buttonProps={{
          variant: "outlined",
          sx: { backgroundColor: "#fff", color: "#333" },
        }}
      />
      <CheckboxButton
        data={SENOR_OPTIONS}
        buttonLabel="Select Sensor"
        checkMasterLabel="All Sensors"
        buttonProps={{
          variant: "outlined",
          sx: { backgroundColor: "#fff", color: "#333" },
        }}
      />
      <SimpleDatePicker label="From" inputProps={{}} />
      <SimpleDatePicker label="To" inputProps={{}} />
    </Stack>
  );
};

const ACTIVITY_DATA = [

  {
    _id: '1',
    timestamp: '1654619880',
    site: {
      _id: '1',
      name: 'Ho Sheung Heung',
      tag: 'N.T.'
    },
    location: '[HSH-005]',
    type: 'feeding',
    message: '[fish meat] weight: 200g',
  },
  {
    _id: '2',
    timestamp: '1654636680',
    site: {
      _id: '1',
      name: 'Ho Sheung Heung',
      tag: 'N.T.'
    },
    location: '[HSH-005]',
    type: 'maintenance',
    message: '[clean filter] clean filter cotton',
  },

  {
    _id: '3',
    timestamp: '1654410720',
    site: {
      _id: '1',
      name: 'Ho Sheung Heung',
      tag: 'N.T.'
    },
    location: '[HSH-005]',
    type: 'maintenance',
    message: '[clean filter] clean filter cotton',
  },
  {
    _id: '4',
    timestamp: '1654522800',
    site: {
      _id: '1',
      name: 'Ho Sheung Heung',
      tag: 'N.T.'
    },
    location: '[HSH-Env]',
    type: 'alert',
    message: '[humidity warning] Humidity is high. Raning is coming',
  },
  {
    _id: '5',
    timestamp: '1654526280',
    site: {
      _id: '1',
      name: 'Ho Sheung Heung',
      tag: 'N.T.'
    },
    location: '[HSH-005]',
    type: 'feeding',
    message: '[small black feed] weight: 50g',
  },

  {
    _id: '6',
    timestamp: '1654497480',
    site: {
      _id: '1',
      name: 'Ho Sheung Heung',
      tag: 'N.T.'
    },
    location: '[HSH-005]',
    type: 'feeding',
    message: '[small black feed] weight: 50g',
  },
  {
    _id: '7',
    timestamp: '1650201900',
    site: {
      _id: '1',
      name: 'Ho Sheung Heung',
      tag: 'N.T.'
    },
    location: '[HSH-002]',
    type: 'feeding',
    message: '[small black feed] weight: 20g',
  },
  {
    _id: '8',
    timestamp: '1650186720',
    site: {
      _id: '1',
      name: 'Ho Sheung Heung',
      tag: 'N.T.'
    },
    location: '[HSH-002]',
    type: 'feeding',
    message: '[small black feed] weight: 25g',
  },
  {
    _id: '9',
    timestamp: '1649865900',
    site: {
      _id: '1',
      name: 'Ho Sheung Heung',
      tag: 'Hairy Crab'
    },
    location: '[HSH-002]',
    type: 'feeding',
    message: '[small black feed] weight: 12g',
  }
]

const Activity = () => {
  const renderCard = React.useCallback((item) => {
    const date = DateTime.fromSeconds(parseInt(item.timestamp));
    const isToday = Math.abs(date.diffNow('days')) <=1;
    const isYesterday = Math.abs(date.diffNow('days')) < 2 && Math.abs(date.diffNow('days')) > 1;
    let shownDate = date.toFormat('yyyy-MM-dd hh:mm');
    if (isToday) {
      shownDate = 'Today';
    }
    if (isYesterday) {
      shownDate = 'Yesterday';
    }
    return (
      <Card sx={{ padding: 2, mb: 2 }}>
        <CardContent>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography>{shownDate}</Typography>
              <Typography>{item.site.name}</Typography>
              <Typography><span>{item.location}</span><span>{item.message}</span></Typography>
            </Box>
            <Box>
              <Typography>{item.type}</Typography>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    )
  }, []);
  return (
    <Box>
      <Typography>Activity</Typography>
      <ActivityFilter />
      <TabCardList
        tabHeading={[
          { label: 'All', key: '0' },
          { label: 'Alert', key: '1' },
          { label: 'Settings', key: '2' }
        ]}
        tabContent={[
          { data: ACTIVITY_DATA },
          { data: ACTIVITY_DATA.filter(data => data.type === 'alert') },
          { data: ACTIVITY_DATA.filter(data => data.type === 'setting') }
        ]}
        renderCard={renderCard}
      />
    </Box>
  );
};
export default Activity;
