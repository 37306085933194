import { createContext, useContext, useEffect, useCallback, useRef, useSyncExternalStore } from "react";
import useGetGraphDataQuery from "./useGetGraphDataQuery";


// https://github.com/jherr/fast-react-context/blob/main/fast-context/src/App.tsx

const DeviceValueChartDataContext = createContext([]);
const DeviceValueChartLineData = ({
  deviceID,
  valueType,
  range,
  addData,
}) => {
  const { data } = useGetGraphDataQuery({ deviceID, valueType, range });
  // console.log({ deviceID, valueType, range, data });
  useEffect(() => {
    const key = `${deviceID}-${valueType}`;
    if (data) {
      const processedData = data?.map(({ dateStr, value }) => ({
        // date: DateTime.fromISO(dateStr).toJSDate(),
        value: Number(value),
        key,
        dateStr,
        valueType,
      }))
      addData({ [key]: processedData });
    }
    return () => addData({ [key]: null });
  }, [data, addData, deviceID, valueType]);
  return null; // render nothing
};

const useDeviceValueStore = () => {
  const store = useRef({});
  const get = useCallback(() => store.current, []);

  const subscribers = useRef(new Set());

  const set = useCallback((value) => {
    store.current = { ...store.current, ...value };
    subscribers.current.forEach((callback) => callback(store.current));
  }, []);

  const subscribe = useCallback((callback) => {
    subscribers.current.add(callback);
    return () => subscribers.current.delete(callback);
  }, []);

  return {
    get,
    set,
    subscribe,
  };
}

export function useDeviceValueChartData(selector = (v => v)) {
  const store = useContext(DeviceValueChartDataContext);
  if (!store) {
    throw new Error("Store not found");
  }

  const state = useSyncExternalStore(store.subscribe, () => selector(store.get()));
  return state;
}

export const DeviceValueChartDataProvider = ({ deviceValue = [], range = '1D', children }) => {
  const value = useDeviceValueStore();
  return (
    <DeviceValueChartDataContext.Provider value={value}>
      {deviceValue?.map(({ deviceID, valueType }) => <DeviceValueChartLineData key={`${deviceID}-${valueType}-${range}`} deviceID={deviceID} valueType={valueType} range={range} addData={value.set} />)}
      {children}
    </DeviceValueChartDataContext.Provider>
  );
}
