import React from "react";
import { Box, Container, Typography } from "@mui/material";
import SensetekLogo from '../../assets/Logo/sensetek_logo_horizontal_black.png';


const ContentContainer = ({
    children,
    title,
    description,
    sensetekLogo,
    ...props
}) => {
    return (
        <Container sx={{ py:'100px', display: 'flex', justifyContent: 'center', backgroundColor: '#F4F6F8' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems:'center', width: 720, py: { sm: 1, md: 10 }, px: { sm: 1, md: 15 }, boxShadow: { md: 5 } }}>

                {/* logo+container title */}
                {sensetekLogo ? (
                    <Box justifyContent='center'>
                        <Box component={'img'} src={SensetekLogo} sx={{ maxWidth: '100px' }} alt="sensetekLogo" />
                    </Box>
                ) : null
                }
                {
                    title ? (
                        <Box sx={{ textAlign: 'center', paddingTop: '10px', paddingBottom: '30px' }}>
                            <Typography variant="h4" component="h1" textAlign="center" >{title}</Typography>
                        </Box>
                    ) : null
                }
                {
                    description ? (
                        <Box sx={{ textAlign: 'center', paddingTop: '20px', paddingBottom: '10px' }}>
                            <Typography variant="p" component="h2" textAlign="center" >{description}</Typography>
                        </Box>
                    ) : null
                }

                {children}
            </Box>
        </Container>
    );
};
export default ContentContainer;
