import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  user: null,
  redirectTo: null,
};
const accountSlicer = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setRedirectTo: (state, { payload }) => {
      state.redirectTo = payload;
    },
    logout: () => initialState,
    login: (state, { payload }) => {
      state.user = payload;
    },
  },
})

export const { setRedirectTo, logout, login } = accountSlicer.actions;
export const AccountActions = { setRedirectTo, logout, login };
export default accountSlicer.reducer;