import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  snackbarMessage: null,
  alert: {
    message: null,
    severity: 'info',
  },
};

const CommonSlicer = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setSnackbarMessage: (state, { payload }) => {
      state.snackbarMessage = payload;
    },
    setAlertMessage: (state, { payload: { message, severity = 'info' } }) => {
      state.alert = {
        message,
        severity
      };
    },
  },
})

export const { setSnackbarMessage, setAlertMessage } = CommonSlicer.actions;
export const CommonActions = { setSnackbarMessage, setAlertMessage };
export default CommonSlicer.reducer;